export const environment = {
    production: false,
    panel: 'diamondv8_',
    domain: "2023exch.com",
    baseUrl: "https://api.2023exch.com",
    pollers: {
        oddsData: 600,
        listOddsData: 10000,
        scoresData: 1000,
    },
    trafficTag: '',
    noDataMsg: 'No Data Found',
    isMobile: true,
    isBetOpenEnv : false,
    wpLinkShowPages: '/sports, /home',
    stakes: { "stake_name_1": "100", "stake_name_2": "5000", "stake_name_3": "500", "stake_name_4": "25000", "stake_name_5": "50000", "stake_name_6": "100000", "stake_name_7": "500000", "stake_name_8": "1000000", "stake_amount_1": 100, "stake_amount_2": 5000, "stake_amount_3": 500, "stake_amount_4": 25000, "stake_amount_5": 50000, "stake_amount_6": 100000, "stake_amount_7": 500000, "stake_amount_8": 1000000 },
    horseRaceJockeyImgPrefix: 'https://content-cache.cdnbf.net/feeds_images/Horses/SilkColours/',
    desktopStyles: [],
    mobileStyles: [],
    host: {
        domain: '',
        apiDomain: '',
        name: '',
        dName: '',
        ext: '',
        theme: '',
        email: '',
        content: '',
        whatsapptext: '',
        demoLogin: false,
        guestEnable: false,
        signUpActive: false,
        iframeDomainUrl: '',
        depoWithdraw: false,
        social: {
            isShow: false,
            wpLink: '',
            fbLink: '',
            instaLink: '',
            telLink: '',
            tel2Link:'',
        },
        footer: {
            isShow: false,
        },
        ftLivetvData: 'https://e765432.xyz/static/cc6c487c44b971286c3d0d1e24649619b4681674/getdata.php?chid=',
        pusher: {
            key: '',
            cluster: '',
        },
        downloadApk: '',
        mThemePath: [''],
        dThemePath: [''],
    },
    hosts: [
        {
            domain: 'localhost',
            apiDomain: 'localhost',
            name: 'localhost',
            dName: 'LocalHost',
            ext: '.com',
            theme: 'blue-body',
            email: 'contactmybetbhai@gmail.com',
            content: '',
            whatsapptext: '',
            guestEnable: false,
            demoLogin: true,
            signUpActive: false,
            iframeDomainUrl: '',
            depoWithdraw: false,
            social: {
                isShow: false,
                wpLink: '',
                fbLink: '',
                instaLink: '',
                telLink: '',
                tel2Link:'',
            },
            footer: {
                isShow: false,
            },
            ftLivetvData: 'https://e765432.xyz/static/cc6c487c44b971286c3d0d1e24649619b4681674/getdata.php?chid=',
            pusher: {
                key: 'c8bbdb36496c73b41567',
                cluster: 'eu'
            },
            downloadApk: '',
            mThemePath: ['assets/css/mobile/common.css', 'assets/css/mobile/m-style.css', 'assets/css/mobile/custom.css', 'assets/css/mobile/theme.css'],
            dThemePath: ['assets/css/desktop/common.css', 'assets/css/desktop/style.css', 'assets/css/desktop/custom.css', 'assets/css/desktop/theme.css'],
        },
        {
            domain: 'magicexch.com',
            apiDomain: 'magicexch.com',
            name: 'magicexch',
            dName: 'magicexch',
            ext: '.com',
            theme: 'blue-body',
            email: 'magicexchinfo@gmail.com',
            content: '',
            whatsapptext: '',
            guestEnable: false,
            demoLogin: true,
            signUpActive: false,
            iframeDomainUrl: '',
            depoWithdraw: false,
            social: {
                isShow: false,
                wpLink: '',
                fbLink: '',
                instaLink: '',
                telLink: '',
                tel2Link:'',
            },
            footer: {
                isShow: false,
            },
            ftLivetvData: 'https://e765432.xyz/static/cc6c487c44b971286c3d0d1e24649619b4681674/getdata.php?chid=',
            pusher: {
                key: 'c8bbdb36496c73b41567',
                cluster: 'eu'
            },
            downloadApk: 'https://application-hub.pages.dev/MagicExch.apk',
            mThemePath: ['assets/css/mobile/common.css', 'assets/css/mobile/m-style.css', 'assets/css/mobile/custom.css', 'assets/css/mobile/theme.css'],
            dThemePath: ['assets/css/desktop/common.css', 'assets/css/desktop/style.css', 'assets/css/desktop/custom.css', 'assets/css/desktop/theme.css'],
        },
        {
            domain: 'mymatch99.com',
            apiDomain: 'mymatch99.com',
            name: 'mymatch99',
            dName: 'mymatch99',
            ext: '.com',
            theme: 'blue-body',
            email: 'mymatch99info@gmail.com',
            content: '',
            whatsapptext: '',
            guestEnable: false,
            demoLogin: true,
            signUpActive: false,
            iframeDomainUrl: '',
            depoWithdraw: false,
            social: {
                isShow: false,
                wpLink: '',
                fbLink: '',
                instaLink: '',
                telLink: '',
                tel2Link:'',
            },
            footer: {
                isShow: false,
            },
            ftLivetvData: 'https://e765432.xyz/static/cc6c487c44b971286c3d0d1e24649619b4681674/getdata.php?chid=',
            pusher: {
                key: 'c8bbdb36496c73b41567',
                cluster: 'eu'
            },
            downloadApk: '',
            mThemePath: ['assets/css/mobile/common.css', 'assets/css/mobile/m-style.css', 'assets/css/mobile/custom.css', 'assets/css/mobile/theme_myMatch.css'],
            dThemePath: ['assets/css/desktop/common.css', 'assets/css/desktop/style.css', 'assets/css/desktop/custom.css', 'assets/css/desktop/theme_myMatch.css'],
        },
        {
            domain: 'shakunee247.com',
            apiDomain: 'shakunee247.com',
            name: 'shakunee247',
            dName: 'shakunee247',
            ext: '.com',
            theme: 'blue-body',
            email: 'shakunee247info@gmail.com',
            content: '',
            whatsapptext: '',
            guestEnable: false,
            demoLogin: true,
            signUpActive: false,
            iframeDomainUrl: '',
            depoWithdraw: false,
            social: {
                isShow: false,
                wpLink: '',
                fbLink: '',
                instaLink: '',
                telLink: '',
                tel2Link:'',
            },
            footer: {
                isShow: false,
            },
            ftLivetvData: 'https://e765432.xyz/static/cc6c487c44b971286c3d0d1e24649619b4681674/getdata.php?chid=',
            pusher: {
                key: 'c8bbdb36496c73b41567',
                cluster: 'eu'
            },
            downloadApk: '',
            mThemePath: ['assets/css/mobile/common.css', 'assets/css/mobile/m-style.css', 'assets/css/mobile/custom.css', 'assets/css/mobile/theme_shakunee247.css'],
            dThemePath: ['assets/css/desktop/common.css', 'assets/css/desktop/style.css', 'assets/css/desktop/custom.css', 'assets/css/desktop/theme_shakunee247.css'],
        },
        {
            domain: 'wold77.com',
            apiDomain: 'wold77.com',
            name: 'wold77',
            dName: 'wold77',
            ext: '.com',
            theme: 'blue-body',
            email: 'wold77info@gmail.com',
            content: '',
            whatsapptext: '',
            guestEnable: false,
            demoLogin: true,
            signUpActive: false,
            iframeDomainUrl: '',
            depoWithdraw: false,
            social: {
                isShow: false,
                wpLink: '',
                fbLink: '',
                instaLink: '',
                telLink: '',
                tel2Link:'',
            },
            footer: {
                isShow: false,
            },
            ftLivetvData: 'https://e765432.xyz/static/cc6c487c44b971286c3d0d1e24649619b4681674/getdata.php?chid=',
            pusher: {
                key: 'c8bbdb36496c73b41567',
                cluster: 'eu'
            },
            downloadApk: '',
            mThemePath: ['assets/css/mobile/common.css', 'assets/css/mobile/m-style.css', 'assets/css/mobile/custom.css', 'assets/css/mobile/theme_wold77.css'],
            dThemePath: ['assets/css/desktop/common.css', 'assets/css/desktop/style.css', 'assets/css/desktop/custom.css', 'assets/css/desktop/theme_wold77.css'],
        },
        {
            domain: '11starss.in',
            apiDomain: '11starss.in',
            name: '11starss',
            dName: '11starss',
            ext: '.in',
            theme: 'blue-body',
            email: '11starssinfo@gmail.com',
            content: '',
            whatsapptext: '',
            guestEnable: false,
            demoLogin: true,
            iframeDomainUrl: 'https://77panel.com/',
            signUpActive: false,
            depoWithdraw: false,
            social: {
                isShow: false,
                wpLink: '',
                fbLink: '',
                instaLink: '',
                telLink: '',
                tel2Link:'',
            },
            footer: {
                isShow: false,
            },
            ftLivetvData: 'https://e765432.xyz/static/cc6c487c44b971286c3d0d1e24649619b4681674/getdata.php?chid=',
            pusher: {
                key: '4ae8dfadca375e6d7f9f',
                cluster: 'eu'
            },
            downloadApk: '',
            mThemePath: ['assets/css/mobile/common.css', 'assets/css/mobile/m-style.css', 'assets/css/mobile/custom.css', 'assets/css/mobile/theme_11star.css'],
            dThemePath: ['assets/css/desktop/common.css', 'assets/css/desktop/style.css', 'assets/css/desktop/custom.css', 'assets/css/desktop/theme_11star.css'],
        },
        {
            domain: '33exch.com',
            apiDomain: '33exch.com',
            name: '33exch',
            dName: '33exch',
            ext: '.com',
            theme: 'blue-body',
            email: '33exchinfo@gmail.com',
            content: '',
            whatsapptext: '',
            guestEnable: true,
            demoLogin: true,
            iframeDomainUrl: 'https://77panel.com/',
            signUpActive: true,
            depoWithdraw: true,
            social: {
                isShow: false,
                wpLink: '',
                fbLink: '',
                instaLink: '',
                telLink: '',
                tel2Link:'',
            },
            footer: {
                isShow: false,
            },
            ftLivetvData: 'https://e765432.xyz/static/cc6c487c44b971286c3d0d1e24649619b4681674/getdata.php?chid=',
            pusher: {
                key: '4ae8dfadca375e6d7f9f',
                cluster: 'eu'
            },
            downloadApk: '',
            mThemePath: ['assets/css/mobile/common.css', 'assets/css/mobile/m-style.css', 'assets/css/mobile/custom.css', 'assets/css/mobile/theme.css'],
            dThemePath: ['assets/css/desktop/common.css', 'assets/css/desktop/style.css', 'assets/css/desktop/custom.css', 'assets/css/desktop/theme.css'],
        },
        {
            domain: 'aarav999.com',
            apiDomain: 'aarav999.com',
            name: 'aarav999',
            dName: 'aarav999',
            ext: '.com',
            theme: 'blue-body',
            email: 'aarav999info@gmail.com',
            content: '',
            whatsapptext: '',
            guestEnable: false,
            demoLogin: true,
            signUpActive: false,
            iframeDomainUrl: '',
            depoWithdraw: false,
            social: {
                isShow: false,
                wpLink: '',
                fbLink: '',
                instaLink: '',
                telLink: '',
                tel2Link:'',
            },
            footer: {
                isShow: false,
            },
            ftLivetvData: 'https://e765432.xyz/static/cc6c487c44b971286c3d0d1e24649619b4681674/getdata.php?chid=',
            pusher: {
                key: '76d7c55c2b5f8747ffd4',
                cluster: 'eu'
            },
            downloadApk: 'https://application-hub.pages.dev/aarav999.apk',
            mThemePath: ['assets/css/mobile/common.css', 'assets/css/mobile/m-style.css', 'assets/css/mobile/custom.css', 'assets/css/mobile/m-theme_aarav999.css'],
            dThemePath: ['assets/css/desktop/common.css', 'assets/css/desktop/style.css', 'assets/css/desktop/custom.css', 'assets/css/desktop/theme_aarav999.css'],
        },
        {
            domain: 'lio777.com',
            apiDomain: 'lio777.com',
            name: 'lio777',
            dName: 'lio777',
            ext: '.com',
            theme: 'blue-body',
            email: 'lio777info@gmail.com',
            content: '',
            whatsapptext: '',
            guestEnable: false,
            demoLogin: true,
            signUpActive: false,
            iframeDomainUrl: '',
            depoWithdraw: false,
            social: {
                isShow: false,
                wpLink: '',
                fbLink: '',
                instaLink: '',
                telLink: '',
                tel2Link:'',
            },
            footer: {
                isShow: false,
            },
            ftLivetvData: 'https://e765432.xyz/static/cc6c487c44b971286c3d0d1e24649619b4681674/getdata.php?chid=',
            pusher: {
                key: '76d7c55c2b5f8747ffd4',
                cluster: 'eu'
            },
            downloadApk: 'https://application-hub.pages.dev/lio777.apk',
            mThemePath: ['assets/css/mobile/common.css', 'assets/css/mobile/m-style.css', 'assets/css/mobile/custom.css', 'assets/css/mobile/m-theme_aarav999.css'],
            dThemePath: ['assets/css/desktop/common.css', 'assets/css/desktop/style.css', 'assets/css/desktop/custom.css', 'assets/css/desktop/theme_aarav999.css'],
        },
        {
            domain: 'crickbuzz247.com',
            apiDomain: 'crickbuzz247.com',
            name: 'crickbuzz247',
            dName: 'crickbuzz247',
            ext: '.com',
            theme: 'blue-body',
            email: 'crickbuzz247info@gmail.com',
            content: '',
            whatsapptext: '',
            guestEnable: false,
            demoLogin: true,
            signUpActive: false,
            iframeDomainUrl: '',
            depoWithdraw: false,
            social: {
                isShow: false,
                wpLink: '',
                fbLink: '',
                instaLink: '',
                telLink: '',
                tel2Link:'',
            },
            footer: {
                isShow: false,
            },
            ftLivetvData: 'https://e765432.xyz/static/cc6c487c44b971286c3d0d1e24649619b4681674/getdata.php?chid=',
            pusher: {
                key: '76d7c55c2b5f8747ffd4',
                cluster: 'eu'
            },
            downloadApk: 'https://application-hub.pages.dev/crickbuzz247.apk',
            mThemePath: ['assets/css/mobile/common.css', 'assets/css/mobile/m-style.css', 'assets/css/mobile/custom.css', 'assets/css/mobile/theme_crickbuzz247.css'],
            dThemePath: ['assets/css/desktop/common.css', 'assets/css/desktop/style.css', 'assets/css/desktop/custom.css', 'assets/css/desktop/theme_crickbuzz247.css'],
        },
        {
            domain: 'kingcrex.com',
            apiDomain: 'kingcrex.com',
            name: 'kingcrex',
            dName: 'kingcrex',
            ext: '.com',
            theme: 'blue-body',
            email: '',
            content: '',
            whatsapptext: '',
            guestEnable: true,
            demoLogin: true,
            iframeDomainUrl: 'https://panel.freeplay24.com/',
            signUpActive: true,
            depoWithdraw: true,
            social: {
                isShow: false,
                wpLink: '',
                fbLink: '',
                instaLink: '',
                telLink: '',
                tel2Link:'',
            },
            footer: {
                isShow: false,
            },
            ftLivetvData: 'https://e765432.xyz/static/cc6c487c44b971286c3d0d1e24649619b4681674/getdata.php?chid=',
            pusher: {
                key: 'fdf9a08dc1f2bfa9cffa',
                cluster: 'eu'
            },
            downloadApk: '',
            mThemePath: ['assets/css/mobile/common.css', 'assets/css/mobile/m-style.css', 'assets/css/mobile/custom.css', 'assets/css/mobile/theme.css'],
            dThemePath: ['assets/css/desktop/common.css', 'assets/css/desktop/style.css', 'assets/css/desktop/custom.css', 'assets/css/desktop/theme.css'],
        },
        {
            domain: 'zebraexch9.com',
            apiDomain: 'zebraexch9.com',
            name: 'zebraexch9',
            dName: 'zebraexch9',
            ext: '.com',
            theme: 'blue-body',
            email: '',
            content: '',
            whatsapptext: '',
            guestEnable: false,
            demoLogin: true,
            signUpActive: false,
            iframeDomainUrl: '',
            depoWithdraw: false,
            social: {
                isShow: false,
                wpLink: '',
                fbLink: '',
                instaLink: '',
                telLink: '',
                tel2Link:'',
            },
            footer: {
                isShow: false,
            },
            ftLivetvData: 'https://e765432.xyz/static/cc6c487c44b971286c3d0d1e24649619b4681674/getdata.php?chid=',
            pusher: {
                key: 'c8bbdb36496c73b41567',
                cluster: 'eu'
            },
            downloadApk: '',
            mThemePath: ['assets/css/mobile/common.css', 'assets/css/mobile/m-style.css', 'assets/css/mobile/custom.css', 'assets/css/mobile/theme_zebraexch9.css'],
            dThemePath: ['assets/css/desktop/common.css', 'assets/css/desktop/style.css', 'assets/css/desktop/custom.css', 'assets/css/desktop/theme_zebraexch9.css'],
        },
        {
            domain: '1xbetfair.org',
            apiDomain: '1xbetfair.org',
            name: '1xbetfair',
            dName: '1xbetfair',
            ext: '.org',
            theme: 'blue-body',
            email: '',
            content: '',
            whatsapptext: '',
            guestEnable: false,
            demoLogin: true,
            signUpActive: false,
            iframeDomainUrl: '',
            depoWithdraw: false,
            social: {
                isShow: false,
                wpLink: '',
                fbLink: '',
                instaLink: '',
                telLink: '',
                tel2Link:'',
            },
            footer: {
                isShow: false,
            },
            ftLivetvData: 'https://e765432.xyz/static/cc6c487c44b971286c3d0d1e24649619b4681674/getdata.php?chid=',
            pusher: {
                key: '76d7c55c2b5f8747ffd4',
                cluster: 'eu'
            },
            downloadApk: '',
            mThemePath: ['assets/css/mobile/common.css', 'assets/css/mobile/m-style.css', 'assets/css/mobile/custom.css', 'assets/css/mobile/theme_1xbetfair.css'],
            dThemePath: ['assets/css/desktop/common.css', 'assets/css/desktop/style.css', 'assets/css/desktop/custom.css', 'assets/css/desktop/theme_1xbetfair.css'],
        },
        {
            domain: 'indusbook247.com',
            apiDomain: 'indusbook247.com',
            name: 'indusbook247',
            dName: 'indusbook247',
            ext: '.com',
            theme: 'blue-body',
            email: '',
            content: '',
            whatsapptext: '',
            guestEnable: false,
            demoLogin: true,
            signUpActive: false,
            iframeDomainUrl: '',
            depoWithdraw: false,
            social: {
                isShow: false,
                wpLink: '',
                fbLink: '',
                instaLink: '',
                telLink: '',
                tel2Link:'',
            },
            footer: {
                isShow: false,
            },
            ftLivetvData: 'https://e765432.xyz/static/cc6c487c44b971286c3d0d1e24649619b4681674/getdata.php?chid=',
            pusher: {
                key: 'c8bbdb36496c73b41567',
                cluster: 'eu'
            },
            downloadApk: '',
            mThemePath: ['assets/css/mobile/common.css', 'assets/css/mobile/m-style.css', 'assets/css/mobile/custom.css', 'assets/css/mobile/theme_indusbook247.css'],
            dThemePath: ['assets/css/desktop/common.css', 'assets/css/desktop/style.css', 'assets/css/desktop/custom.css', 'assets/css/desktop/theme_indusbook247.css'],
        },
        {
          domain: 'allbet9.com',
          apiDomain: 'magicexch.com',
          name: 'allbet9',
          dName: 'allbet9',
          ext: '.com',
          theme: 'blue-body',
          email: '',
          content: '',
          whatsapptext: '',
          guestEnable: false,
          demoLogin: true,
          signUpActive: false,
          iframeDomainUrl: '',
          depoWithdraw: false,
          social: {
              isShow: false,
              wpLink: '',
              fbLink: '',
              instaLink: '',
              telLink: '',
              tel2Link:'',
          },
          footer: {
            isShow: false,
        },
          ftLivetvData: 'https://e765432.xyz/static/cc6c487c44b971286c3d0d1e24649619b4681674/getdata.php?chid=',
          pusher: {
              key: 'c8bbdb36496c73b41567',
              cluster: 'eu'
          },
          downloadApk: 'https://application-hub.pages.dev/MagicExch.apk',
          mThemePath: ['assets/css/mobile/common.css', 'assets/css/mobile/m-style.css', 'assets/css/mobile/custom.css', 'assets/css/mobile/theme.css'],
          dThemePath: ['assets/css/desktop/common.css', 'assets/css/desktop/style.css', 'assets/css/desktop/custom.css', 'assets/css/desktop/theme.css'],
      },
      {
          domain: '1xbetfair.in',
          apiDomain: '1xbetfair.in',
          name: '1xbetfair',
          dName: '1xbetfair',
          ext: '.in',
          theme: 'blue-body',
          email: 'info1xbetfair@gmail.com',
          content: 'Welcome to 1xbetfair, a one-stop solution for all your needs related to playing online betting and casino games. Your every bet and spin on our platform takes you a step forward to huge winnings and enticing experiences. 1xbetfair is renowned for offering a top-notch online gaming experience to its users, whether you are browsing our massive array of casino games or you are betting on tennis, cricket, and football. Responsible gaming is always the topmost priority of our platform, particularly for those players who are 18 years of age and above. 1xbetfair is licensed and regulated by the Curacao Authority and our N.V. License No.is: B2C-669UN7GS-2678JAZ. It is the responsibility of our users to make sure that they make use of our services lawfully in their jurisdiction incorporating non-gaming territories. Join our platform now to have a fantastic gaming experience.',
          whatsapptext: 'Get Your Instatnt ID From Whatsapp',
          guestEnable: true,
          demoLogin: true,
          iframeDomainUrl: 'https://panel.freeplay24.com/',
          signUpActive: true,
          depoWithdraw: true,
          social: {
              isShow: true,
              wpLink: 'https://wa.link/1xsupport',
              fbLink: 'https://www.facebook.com/1xbetfair',
              instaLink: 'https://www.instagram.com/1xbetfair',
              telLink: 'https://t.me/onexbetfair',
              tel2Link: 'https://t.me/onexbetfairsupport',
          },
          footer: {
                isShow: true,
            },
          ftLivetvData: 'https://e765432.xyz/static/cc6c487c44b971286c3d0d1e24649619b4681674/getdata.php?chid=',
          pusher: {
              key: 'fdf9a08dc1f2bfa9cffa',
              cluster: 'eu'
          },
          downloadApk: '',
          mThemePath: ['assets/css/mobile/common.css', 'assets/css/mobile/m-style.css', 'assets/css/mobile/custom.css', 'assets/css/mobile/theme_1xbetfair.css'],
          dThemePath: ['assets/css/desktop/common.css', 'assets/css/desktop/style.css', 'assets/css/desktop/custom.css', 'assets/css/desktop/theme_1xbetfair.css'],
      },
      {
        domain: '1xbetfair.com',
        apiDomain: '1xbetfair.com',
        name: '1xbetfair',
        dName: '1xbetfair',
        ext: '.com',
        theme: 'blue-body',
        email: 'info1xbetfair@gmail.com',
        content: 'Welcome to 1xbetfair, a one-stop solution for all your needs related to playing online betting and casino games. Your every bet and spin on our platform takes you a step forward to huge winnings and enticing experiences. 1xbetfair is renowned for offering a top-notch online gaming experience to its users, whether you are browsing our massive array of casino games or you are betting on tennis, cricket, and football. Responsible gaming is always the topmost priority of our platform, particularly for those players who are 18 years of age and above. 1xbetfair is licensed and regulated by the Curacao Authority and our N.V. License No.is: B2C-669UN7GS-2678JAZ. It is the responsibility of our users to make sure that they make use of our services lawfully in their jurisdiction incorporating non-gaming territories. Join our platform now to have a fantastic gaming experience.',
        whatsapptext: 'Get Your Instatnt ID From Whatsapp',
        guestEnable: true,
        demoLogin: true,
        iframeDomainUrl: 'https://panel.freeplay24.com/',
        signUpActive: true,
        depoWithdraw: true,
        social: {
            isShow: true,
            wpLink: 'https://wa.link/1xsupport',
            fbLink: 'https://www.facebook.com/1xbetfair',
            instaLink: 'https://www.instagram.com/1xbetfair',
            telLink: 'https://t.me/onexbetfair',
            tel2Link: 'https://t.me/onexbetfairsupport',
        },
        footer: {
              isShow: true,
          },
        ftLivetvData: 'https://e765432.xyz/static/cc6c487c44b971286c3d0d1e24649619b4681674/getdata.php?chid=',
        pusher: {
            key: 'fdf9a08dc1f2bfa9cffa',
            cluster: 'eu'
        },
        downloadApk: 'assets/img/1xbetfair/1xbetfair-com.apk',
        mThemePath: ['assets/css/mobile/common.css', 'assets/css/mobile/m-style.css', 'assets/css/mobile/custom.css', 'assets/css/mobile/theme_1xbetfair.css'],
        dThemePath: ['assets/css/desktop/common.css', 'assets/css/desktop/style.css', 'assets/css/desktop/custom.css', 'assets/css/desktop/theme_1xbetfair.css'],
    },
    {
        domain: 'taj365.co',
        apiDomain: 'taj365.co',
        name: 'taj365',
        dName: 'taj365',
        ext: '.co',
        theme: 'blue-body',
        email: '',
        content: '',
        whatsapptext: '',
        guestEnable: false,
        demoLogin: true,
        signUpActive: false,
        iframeDomainUrl: '',
        depoWithdraw: false,
        social: {
            isShow: false,
            wpLink: '',
            fbLink: '',
            instaLink: '',
            telLink: '',
            tel2Link:'',
        },
        footer: {
            isShow: false,
        },
        ftLivetvData: 'https://e765432.xyz/static/cc6c487c44b971286c3d0d1e24649619b4681674/getdata.php?chid=',
        pusher: {
            key: 'c8bbdb36496c73b41567',
            cluster: 'eu'
        },
        downloadApk: 'https://application-hub.pages.dev/MagicExch.apk',
        mThemePath: ['assets/css/mobile/common.css', 'assets/css/mobile/m-style.css', 'assets/css/mobile/custom.css', 'assets/css/mobile/theme.css'],
        dThemePath: ['assets/css/desktop/common.css', 'assets/css/desktop/style.css', 'assets/css/desktop/custom.css', 'assets/css/desktop/theme.css'],
    },
    ],
  };
  